import React, { useEffect, useState, useContext } from 'react'
import { PageRendererProps } from 'gatsby'
import firebase from 'firebase/app'

import UserContext from '@/context/user-context'
import { CommonContext } from '@/context/common-store'
import { useFirebase } from '@/firebase'
import { Container, FooterContainer } from '@/styles'
import Header from './header'
import SEO from './seo'

interface LayoutProps extends PageRendererProps {
    children: React.ReactNode
    title?: string
    description?: string
    image?: string
    article?: boolean
}

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
    const { children, location, title, description, image, article } = props
    const firebaseApp = useFirebase()
    const [ user, setUser ] = useState<firebase.User|null>(null)
    const [ commonState, ] = useContext(CommonContext)

    useEffect(() => {
        if (!firebaseApp) {
            return
        }
        return firebaseApp.auth().onAuthStateChanged((currentUser) => {
            setUser(currentUser)
        })
    }, [firebaseApp])

    useEffect(() => {
        if (commonState.error) {
            console.info(commonState.error)
        }
    }, [commonState.error])

    return (
        <UserContext.Provider value={user}>
            <Container isMain>
                <SEO title={title} description={description} image={image} article={article} />
                <Header location={location.pathname} />
                <main style={{ flexGrow: 1 }}>{children}</main>
                <FooterContainer>&copy; {new Date().getFullYear()} Shan</FooterContainer>
            </Container>
        </UserContext.Provider>
    )
}

export default Layout