import styled from 'styled-components'

export const Tooltip = styled.div`
    span {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s ease-in-out;
    width: ${(props: TooltipProps) => props.width || '80px'};
    max-width: 120px;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 0.7rem;
    color: #fff;
    text-align: center;
    padding: 0.3rem 0;
    border-radius: 0.4rem;
    position: absolute;
    z-index: 2;
    }

    > :first-child:hover + span {
    ${(props: TooltipProps) => !props.iscurrent? `visibility: visible; opacity: 1;` : ""}
    }
`

interface TooltipProps {
    iscurrent?: number;
    width?: number | string;
}

export default Tooltip