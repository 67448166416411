import firebase from 'firebase/app'
import 'firebase/auth'
import { useEffect, useState } from 'react'

const firebaseConfig = {
    apiKey: process.env.GATSBY_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_DATABASE_URL,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
}

let firebaseInstance: (firebase.app.App|null) = null
let authProviderInstance: (firebase.auth.GoogleAuthProvider|null) = null

export const getFirebase = () => {
    if (typeof window !== 'undefined') {
        if (!!firebaseInstance) return firebaseInstance
        firebaseInstance = firebase.initializeApp(firebaseConfig)
        return firebaseInstance
    }
    return null
}

export const useFirebase = () => {
    const [firebaseApp, setFirebaseApp] = useState<firebase.app.App|null>(null)
    useEffect(() => {
        setFirebaseApp(getFirebase())
    }, [])
    return firebaseApp
}

export const getAuthProvider = () => {
    if (typeof window !== 'undefined') {
        if (!!authProviderInstance) return authProviderInstance
        authProviderInstance = new firebase.auth.GoogleAuthProvider()
        return authProviderInstance
    }
    return null
}

export const useAuthProvider = () => {
    const [authProvider, setAuthProvider] = useState<firebase.auth.GoogleAuthProvider|null>(null)
    useEffect(() => {
        setAuthProvider(getAuthProvider())
    }, [])
    return authProvider
}