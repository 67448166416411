import React, { useContext } from 'react'
import styled from 'styled-components'

import { useFirebase, useAuthProvider } from '@/firebase'
import UserContext from '@/context/user-context'
import { CommonContext } from '@/context/common-store'
import { HeaderContainer } from '@/styles'
import IconButton from './icon-button'
import Logo from '@/assets/logo.svg'

const StyledLogo = styled(Logo)`
    height: 100%;
    min-height: 2rem;
    width: 100%;
`

const Navbar = styled.nav`
    position: absolute;
    bottom: 0;
    right: 0;
    float: right;
    z-index: 1;
`

interface HeaderProps {
    location: string;
}

const Header: React.FunctionComponent<HeaderProps> = ({ location }) => {
    const firebase = useFirebase()
    const authProvider = useAuthProvider()
    const user = useContext(UserContext)
    const [, dispatch] = useContext(CommonContext)
    const login = () => {
        if (!user && !!authProvider) {
            firebase?.auth().signInWithPopup(authProvider).catch(error => {
                // console.log(error)
                dispatch({
                    type: 'SET_ERROR',
                    payload: error.message || 'Login failed.'
                })
            })
        }
    }
    const logout = () => {
        firebase?.auth().signOut().catch(error => {
            dispatch({
                type: 'SET_ERROR',
                payload: error.message || 'Login failed.'
            })
        })
    }

    return (
        <HeaderContainer>
            <StyledLogo />
            <Navbar>
            <IconButton
                name="home"
                tooltip="Home"
                to="/"
                iscurrent={location === "/"}
            />
            <IconButton 
                name="menu"
                tooltip={user ? 'Categories' : 'Categories: only available when logged in'}
                disabled={!user}
                tooltipWidth="100px"
            />
            <IconButton
                name="face"
                tooltip="About me"
                to="/about"
                iscurrent={location === "/about"}
                />
            <IconButton
                name="mail"
                tooltip="Mail me"
                to="mailto:contact@shan-web.com"
                external
            />
            {
                !user ? 
                <IconButton
                    name="login"
                    tooltip="Login with Google"
                    onClick={login}
                /> :
                <IconButton
                    name="logout"
                    tooltip="Logout"
                    onClick={logout}
                />
            }
            
            </Navbar>
        </HeaderContainer> 
    )
}

export default Header