import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Tooltip from './tooltip'

const IconLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  ${(props: DomProps) =>
    props.iscurrent ? `pointer-events: none;` : ""}
`

const Icon = styled.div`
  display: inline-block;
  text-decoration: none;
  ${(props: DomProps) =>
    props.iscurrent ? `pointer-events: none;` : ""}
`

const IconExternalLink = styled.a`
  display: inline-block;
  text-decoration: none;
  ${(props: DomProps) =>
    props.iscurrent ? `pointer-events: none;` : ""}
`

const MaterialIcon = styled.p`
  font-family: "Material Icons";
  font-size: 1.7rem;
  color: ${(props: DomProps) => props.iscurrent ? 'var(--secondary-color)' : (props.disabled ? 'var(--light-grey)': 'var(--grey)')};
  display: inline-block;
  padding: 0.3rem;
  ${(props: DomProps) =>
      props.iscurrent
        ? `pointer-events: none;`
        : ""}
    :hover {
        filter: ${(props: DomProps) => !props.disabled ? 'brightness(70%)' : 'unset'};
        cursor: ${(props: DomProps) => !props.disabled ? 'pointer' : 'auto'};
    }

`
interface IconProps {
    name: string;
    tooltip?: string;
    to?: string;
    iscurrent?: boolean;
    disabled?: boolean;
    external?: boolean;
    onClick?: (e: React.MouseEvent) => any;
    tooltipWidth?: number | string;
}

interface DomProps {
  iscurrent?: number;
  disabled?: boolean;
}

const IconButton: React.FunctionComponent<IconProps> = (props) => {
    const TooltipComponent = (
      <Tooltip iscurrent={props.iscurrent ? 1 : 0} width={props.tooltipWidth}>
        <MaterialIcon iscurrent={props.iscurrent ? 1 : 0} disabled={props.disabled}>{props.name}</MaterialIcon>
        {props.tooltip && <span>{props.tooltip}</span>}
      </Tooltip>
    )

    if (!props.to) {
      return <Icon onClick={props.onClick} disabled={props.disabled}>{TooltipComponent}</Icon>
    }

    return !props.external ? (
      <IconLink to={props.to} iscurrent={props.iscurrent ? 1 : 0} disabled={props.disabled}>
        {TooltipComponent}
      </IconLink>
    ) : (
      <IconExternalLink href={props.to} iscurrent={props.iscurrent ? 1 : 0} disabled={props.disabled}>
        {TooltipComponent}
      </IconExternalLink>
    )
}

export default IconButton